import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import CookieConsent from 'react-cookie-consent';
import { createGlobalStyle, ThemeProvider } from 'styled-components'
import Navigation from '../components/Navigation'
import Footer from '../components/Footer'
import theme from '../../config/theme'
import headroom from '../styles/headroom'

const GlobalStyle = createGlobalStyle`
  .gatsby-resp-image-wrapper {
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
    border-radius: ${theme.borderRadius.default};
    .gatsby-resp-image-background-image, .gatsby-resp-image-image {
      border-radius: ${theme.borderRadius.default};
    }
  }
  body {
    -webkit-tap-highlight-color: transparent;
    margin: 0px;
  }
  .gatsby-resp-iframe-wrapper {
    margin-bottom: 2rem;
  }
  ${headroom}
`

export default props => (
  <StaticQuery
    query={graphql`
      query SiteQuery {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `}
    render={ data => <Layout data={ data } { ...props }/> }
  />
)

const Layout = ( props ) => {
  // Define the meta title and description
  const title = props.data.site.siteMetadata.title
  const description = props.data.site.siteMetadata.description

	return(
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{ title }</title>
        <meta name="description" content={ description } />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <html lang="de" />
      </Helmet>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <Navigation/>
        <div>
          { props.children }
        </div>
        <CookieConsent
          location="bottom"
          enableDeclineButton
          buttonText="Einverstanden"
          declineButtonText="Nein Danke"
          style={{ background: "#ff7dd6" }}
          buttonStyle={{ background: "#ffffff" , color: "#ff7dd6", fontSize: "13px" }}
          declineButtonStyle={{ background: "#ffffff" , color: "#ff7dd6", fontSize: "13px" }}
          cookieName="gatsby-gdpr-google-analytics">
          Wir möchten Cookies verwenden, um unsere Website zu verbessern
        </CookieConsent>
        <Footer/>
      </ThemeProvider>
      
    </div>
	)
}
