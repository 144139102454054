import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const Wrapper = styled.header`
  background-attachment: ${props => (props.big ? 'fixed' : '')};;
  min-height: 300px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: ${props => (props.big ? '50vw' : '30vw')};
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    height: ${props => (props.big ? '600px' : '400px')};
    background-attachment: initial;
    min-height: 50%;
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    height: ${props => (props.big ? '500px' : '325px')};
  }
  position: relative;
  overflow: hidden;
`

const Title = styled.h1`
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-5deg);
  z-index: 1000;
  color: ${props => props.theme.colors.white.light};
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: ${props => props.theme.layout.base};
  padding: 0 2rem;
  text-align: center;
  font-size: 2.5rem;
  font-family: ${props => props.theme.fontFamily.heading};
`

const Subtitle = styled.h1`
  position: absolute;
  top: 75%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-5deg);
  z-index: 1000;
  color: ${props => props.theme.colors.primary.base};
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: ${props => props.theme.layout.base};
  padding: 0 2rem;
  text-align: center;
  font-size: 2.5rem;
  font-family: ${props => props.theme.fontFamily.heading};
`

const Text = styled.h2`
  position: absolute;
  top: 85%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  color: ${props => props.theme.colors.white.light};
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: ${props => props.theme.layout.base};
  padding: 0 2rem;
  text-align: center;
  font-size: 1.3rem;
  font-family: ${props => props.theme.fontFamily.intro};
`


const Header = ({ title, subtitle, big, imageUrl, info }) => {

  return (
    <Wrapper big={big}
      style={{
        backgroundImage: `url(${
          imageUrl
        })`,
    }}>
    
      <Title>
        {title}
      </Title>
      <Subtitle>
        {subtitle}
      </Subtitle>
      <Text>
        {info}
      </Text>
    </Wrapper>
  )
}

export default Header

Header.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.bool]),
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.bool]),
  big: PropTypes.bool,
  imageUrl: PropTypes.string,
}

Header.defaultProps = {
  big: false,
  title: false,
  subtitle: false,
  imageUrl: null,
}
