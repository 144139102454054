import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import Headroom from 'react-headroom'
import logo from "../images/logo.png";

const StyledLink = styled.a`
  display: flex;
  font-weight: 700;
  align-items: center;
  img {
    height: 2.5rem;
    margin-bottom: 0;
  }
`

const LogoText = styled.span`
  font-family: ${props => props.theme.fontFamily.heading};
  font-size: 1.8rem;
  margin-left: 0.05rem;
  color: ${props => props.theme.colors.black.pure};
  @media (max-width: 500px) {
    display: none;
  }
`

const Nav = styled.nav`
  display: flex;
  font-size: 1.5rem;
  justify-content: flex-end;
  font-family: ${props => props.theme.fontFamily.heading};
  align-items: center;
  a {
    color: ${props => props.theme.colors.primary.base};
    padding-left: 2rem;
    transition: all ${props => props.theme.transitions.default.duration};
    &:hover {
      color: ${props => props.theme.colors.white.pinkish};
    }
    &:focus {
      color: ${props => props.theme.colors.white.base};
    }
  }
`

const Navigation = () => (
  <Headroom>
    <StyledLink 
      href="/"
      aria-label="Reiseamseln, Back to homepage"
    >
      <img
          src={logo}
          alt="Reiseamseln Logo"
        />
      <LogoText>eiseamseln</LogoText>
    </StyledLink>
    <Nav>
      <Link to="/blogroll">Blog</Link>
    </Nav>
  </Headroom>
)

export default Navigation