import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import Wave from '../elements/Wave'
import Container from '../elements/Container'
import bird from "../images/amsel-tech-logo-white.svg";
import wordmark from "../images/amsel-tech-word-white.svg";

const Wrapper = styled.footer`
  position: relative;
  padding-top: 3rem;
  background: ${props => props.theme.gradient.leftToRight};
  font-family: ${props => props.theme.fontFamily.intro};
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    padding-top: 3rem;
  }
`

const Content = styled.div`
  margin-top: 3rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: ${props => props.theme.colors.white.base};
  a {
    text-decoration: none;
    color: ${props => props.theme.colors.white.base};
    &:hover {
      color: ${props => props.theme.colors.white.base};
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    flex-direction: column;
  }
`

const Item = styled.div`
  font-size: 0.95rem;
  a {
    margin-left: 0.4rem;
    margin-right: 0.4rem;
    display: inline-block;
  }
  text-shadow: ${props => props.theme.shadow.text.small};
`

const Side = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    text-align: center;
  }
`

const Left = styled(Side)`
  text-align: left;
  padding-right: 1rem;
  text-align: center;
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    padding-right: 0;
  }
`

const Right = styled(Side)`
  text-align: right;
  justify-content: space-between;
  padding-left: 1rem;
  text-align: center;
  padding-top: 3rem;
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    padding-left: 0;
    padding-top: 0rem;
    margin-top: 2.5rem;
  }
`

const Copyright = styled.div`
  color: ${props => props.theme.colors.white.base};
  font-size: 0.9rem;
  margin-left: 0.4rem;
  margin-right: 0.4rem;
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    margin-top: 0.5rem;
  }
`

const Footer = class extends React.Component {
  render() {
    return (
      <Wrapper >
        <Wave orientation="top" />
        <Container>
          <Content>
            <Left>
              <div className="column">
                <div className="has-text-white"  style={{ marginTop: '20px'}}              >
                  {"< />"} with ♡ by
                </div>
                <a
                  href="https://amsel.tech"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span>
                    <img
                      src={bird}
                      style={{ width: '8em', height: '5em', padding: '0.5em'}}
                      alt="amsel.tech bird"
                    />
                    <img
                      src={wordmark}
                      style={{ width: '8em', height: '5em', padding: '0em'}}
                      alt="amsel.tech Wordmark" 
                    />
                  </span>
                </a>
              </div>
            </Left>
            <Right>
              <Item>
                <Link to="/impressum" rel="nofollow">
                  Impressum
                </Link>
                <Link to="/datenschutz" rel="nofollow">
                  Datenschutz
                </Link>
              </Item>
              <Copyright>
                Copyright © 2020. Reiseamseln. Alle Rechte vorbehalten.
              </Copyright>
            </Right>
          </Content>
        </Container>
      </Wrapper>
    )
  }
}

export default Footer
